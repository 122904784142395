import { MobileNavBarBrandComponent } from "./mobile-nav-bar-brand.component";
import { MobileNavBarButtonsComponent } from "./mobile-nav-bar-buttons.component";
import { MobileNavBarTabComponent } from "./mobile-nav-bar-tab.component";
import { MobileNavBarTabsComponent } from "./mobile-nav-bar-tabs.component";
import { MobileNavBarComponent } from "./mobile-nav-bar.component";

export const MOBILE_COMPONENTS = [
    MobileNavBarBrandComponent,
    MobileNavBarButtonsComponent,
    MobileNavBarTabComponent,
    MobileNavBarTabsComponent,
    MobileNavBarComponent,
];
