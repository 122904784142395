export const environment = {
      production: false,
      auth0: {
        domain: 'dev-4a14ko0z5godz3iv.us.auth0.com',
        clientId: 'FXryDVAHW9I0E5Bw491BWHIGJxGRXUjw',
        authorizationParams: {
          audience: 'https://hello-world.example.com',
          redirect_uri: 'https://showcasesite.link/callback',
        },
        errorPath: '/callback',
      },
      api: {
        serverUrl: 'https://dgr0168gskmzt.cloudfront.net',
      },
    };
    