import { Component } from '@angular/core';

@Component({
  selector: 'app-nav-bar-brand',
  template: `
    <div class="nav-bar__brand">
      <!-- <a routerLink="/">
        <img
          class="nav-bar__logo"
          src="https://cdn.auth0.com/blog/hub/code-samples/hello-world/auth0-logo.svg"
          alt="Auth0 shield logo"
          width="122"
          height="36"
        />
      </a> -->
      <a style="text-decoration: none" routerLink="/">
        <h1 style="color:white;">
          <img
            class="nav-bar__logo"
            src="assets/apple-touch-icon.png"
            alt="SamAI"
            style="margin-right:.3rem; margin-bottom: .5rem"
          />SamAI
        </h1>
      </a>
    </div>
  `,
})
export class NavBarBrandComponent {}
