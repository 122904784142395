import { NavBarBrandComponent } from "./nav-bar-brand.component";
import { NavBarButtonsComponent } from "./nav-bar-buttons.component";
import { NavBarTabComponent } from "./nav-bar-tab.component";
import { NavBarTabsComponent } from "./nav-bar-tabs.component";
import { NavBarComponent } from "./nav-bar.component";

export const DESKTOP_COMPONENTS = [
    NavBarBrandComponent,
    NavBarButtonsComponent,
    NavBarTabComponent,
    NavBarTabsComponent,
    NavBarComponent
];
